<template>
  <div class="page-hero container-fluid mx-auto">
    <div class="page-hero__inner">
      <figure class="page-hero__bg">
        <picture>
          <source :srcset="item.image_mobile ? item.image_mobile.filename : item.image.filename" media="(max-width: 576px)">
          <img :src="item.image.filename" :alt="item.image.alt" />
        </picture>
      </figure>
      <div class="page-hero__lockup">

        <h1>{{ item.title }}</h1>

        <div class="page-hero__text" v-if="item.text">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Blok } from 'src/modules/vsf-storyblok-module/components'

export default {
  name: 'PageHero',
  extends: Blok
}
</script>

<style scoped lang="scss">
// Media queries
$iphone6: 375px;
$iphone6-plus: 414px;
$iphone4-landscape: 480px;
$small-device: 576px;
$iphone6-landscape: 667px;
$iphone6-plus-landscape: 736px;
$tablet: 768px;
$tablet-medium: 800px;
$tablet-landscape: 1024px;
$not-tablet: $tablet-landscape + 1;
$desktop: 1200px;
$desktop-large: 1400px;
$desktop-x-large: 1600px;
$site-max: 1440px;
$black: #000;
$white: #fff;

// Breakpoint Range
@mixin bp-range($min, $max) {
  @media (min-width: $min) and (max-width: $max - 1) {
    @content;
  }
}

// Breakpoint Max
@mixin bp-max(
  $breakpoint,
  $media-query-type: "max-width",
  $media-query: "#{$media-query-type}: #{$breakpoint - 1}"
) {
  @media (#{$media-query}) {
    @content;
  }
}

// Breakpoint Min
@mixin bp(
  $breakpoint,
  $media-query-type: "min-width",
  $media-query: "#{$media-query-type}: #{$breakpoint}"
) {
  @media (#{$media-query}) {
    @content;
  }
}

@mixin small {
  @include bp($small-device) {
    @content;
  }
}

@mixin tablet {
  @include bp($tablet) {
    @content;
  }
}

@mixin tablet-max {
  @include bp-max($tablet) {
    @content;
  }
}

@mixin tablet-medium {
  @include bp($tablet-medium) {
    @content;
  }
}

@mixin tablet-landscape {
  @include bp($tablet-landscape) {
    @content;
  }
}

@mixin tablet-landscape-max {
  @include bp-max($tablet-landscape) {
    @content;
  }
}

@mixin not-tablet {
  @include bp($not-tablet) {
    @content;
  }
}

@mixin desktop {
  @include bp($desktop) {
    @content;
  }
}

@mixin desktop-max {
  @include bp-max($desktop) {
    @content;
  }
}

@mixin desktop-large {
  @include bp($desktop-large) {
    @content;
  }
}

@mixin desktop-x-large {
  @include bp($desktop-x-large) {
    @content;
  }
}

@mixin max {
  @include bp($site-max) {
    @content;
  }
}

@mixin padding-sides($mobile-padding: 26px, $tablet-landscape-padding: 40px, $desktop-padding: 55px) {
  padding-left: $mobile-padding;
  padding-right: $mobile-padding;

  @include tablet-landscape {
    padding-left: $tablet-landscape-padding;
    padding-right: $tablet-landscape-padding;
  }

  @include desktop {
    padding-left: $desktop-padding;
    padding-right: $desktop-padding;
  }
}

@mixin button(
  $border: null,
  $border-width: 1px,
  $color: '#fff',
  $desktop-styles: true,
  $font-size: 12px,
  $font-size-desktop: 17px,
  $height: "standard",
  $hover: true,
  $letter-spacing: 0.1em,
  $min-width: 120px,
  $min-width-desktop: 150px,
  $padding: null,
  $padding-desktop: null,
  $theme: "dark",
  $width: auto,
  $width-desktop: null
) {
  @if ($theme == "light") {
    $color: $black;
  }
  @if ($theme == "outline") {
    $letter-spacing: 0.2em;
    $font-size: 18px;
    $padding: 20px;
    $width: 100%;
    $border: 1px solid #707070;
    background: transparent;
  }
  @if ($height == 'tall') {
    $font-size: 18px;
    $letter-spacing: 0.2em;
    $min-width-desktop: 275px;
    $padding: 21px 20px 20px;
    $padding-desktop: 21px 48px 20px;
    $width: 100%;
    $width-desktop: auto;
  }
  border: $border;
  border-radius: 0;
  display: inline-block;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  line-height: 17px;
  max-width: 100%;
  min-width: $min-width;
  overflow: visible; // IE
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 250ms, border-color 250ms, color 250ms;
  white-space: nowrap;
  width: $width;


  @if $padding {
    padding: $padding;
  } @else {
    @if $height == "standard" {
      padding: 12px 20px 9px;
    }
  }

  @if $theme == "dark" {
    background: $black;
    border: $border-width solid $black;
    color: $color;
  } @else if $theme == "light" {
    background: transparent;
    @if ($border == null) {
      border: $border-width solid '#FFF';
    } @else {
      border: $border;
    }
    color: $color;
  }

  @if $hover == true {
    .no-touchevents & {
      &:hover {
        @if $theme == "dark" {
          background: transparent;
          border-color: $black;
          color: $black;
        } @else if $theme == "light" or $theme == "outline" {
          background: $black;
          border-color: $black;
          color: '#FFF';
        }
        text-decoration: none;
      }
    }
  }

  @if $desktop-styles {
    @include desktop {
      font-size: $font-size-desktop;
      min-width: $min-width-desktop;
      width: $width-desktop;

      @if $padding-desktop {
        padding: $padding-desktop;
      } @else {
        @if $height == "standard" {
          padding: 19px 25px 18px;
        }
      }
    }
  }

  &:disabled {
    color: #000 !important;
    background-color: #e6e6e6 !important;
    border-color: #e6e6e6 !important;
    pointer-events: none;
  }
  @content;
}

$ns: '.page-hero';

#{$ns} {
  padding: 0 0;
  @include tablet {
    padding: 20px 30px;
  }
  @include desktop {
    padding: 20px 54px;
  }
  &__inner {
    margin: 0 auto;
    //max-width: 1812px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    flex-flow: column nowrap;
    @include tablet {
      min-height: 300px;
    }
    @include tablet-landscape {
      min-height: 350px;
    }
    @include desktop-x-large {
      min-height: 450px;
    }
  }
  &__bg {
    position: relative;
    z-index: 0;
    @include tablet {
      position: absolute;
    }
    top: 0;
    left: 0;
    height: 100%;
    object-fit: cover;
    width: 100%;
    &--overlay {
      &:after {
        @include tablet {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0,0,0,0.5);
          z-index: 2;
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__lockup {
    position: relative;
    z-index: 5;
    padding: 31px 30px 40px;
    .template-page--carry-us-bleu &,
    .template-page--carry-us & {
      padding-bottom: 0;
      @include tablet-landscape {
        padding: 50px 30px 80px;
      }
      @include desktop-x-large {
        padding: 90px 30px 120px;
      }
    }
    @include tablet-landscape {
      padding: 50px 30px 80px;
    }
    @include desktop-x-large {
      padding: 90px 30px 120px;
    }
    text-align: center;
    h1 {
      font-size: 30px;
      font-weight: normal;
      color: #000;
      text-transform: uppercase;
      @include tablet {
        color: #FFF;
        //font-size: 60px;
      }
    }
  }

  &__text {
    line-height: (26/22);
    font-weight: normal;
    color: #000;
    max-width: 841px;
    margin: 10px auto 0;
    font-size: 18px;
    @include tablet {
      font-size: 20px;
      color: #fff;
      width: 80%;
    }
    @include desktop {
      width: 70%;
      font-size: 22px;
      margin: 13px auto 0;
    }
    @include desktop-large {
      width: 100%;
    }
  }

  &__btn {
    @include button($theme: 'dark', $hover: true);
    .template-page--radio & {
      letter-spacing: 0.2em;
      @include bp-max($tablet) {
        @include button($theme: 'light', $hover: true);
        border: 1px solid #000;
      }
    }
    @include tablet-landscape {
      @include button($theme: 'light', $hover: true);
    }
    font-size: 18px !important;
    margin-top: 37px;
    min-width: 275px;
    padding: 19px 25px 18px !important;
    @include desktop {
      min-width: 275px;
    }
  }
  &__contact-modal {
    background-color: #fff;
    padding: 80px 20px;
    position: relative;
    height: 100vh;
    overflow: auto;
    @include tablet {
      height: auto;
      padding: 60px 50px;
    }
    @include tablet-landscape {
      padding: 40px 50px;
    }
    @include desktop-x-large {
      padding: 50px 75px;
    }
    @include desktop-x-large {
      padding: 70px 75px;
    }
  }
  &__contact-modal-close {
    width: 100%;
    height: 100%;
    span {

    }
  }
}

.template-page--help {
  .index-section--page-hero {
    margin-bottom: 0 !important;
  }
  .lity-container {
    @include bp-max($tablet) {
      width: 100%;
    }
  }
  .modal-close-button {
    top: 20px;
    right: 20px;
    @include bp(576px) {
      top: 30px ;
      right: 27px;
    }
    @include desktop-x-large {
      top: 30px;
      right: 27px;
      width: 58px;
      height: 58px;
    }
  }
  button.lity-close {
    display: none;
  }
}
</style>
